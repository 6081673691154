<template class="newsViewDiv">
    <lazy-component>
        <div class="newsViewContainer">
            <div class="newsViewIconDiv">
                <a-icon class="newsViewIcon" type="close" :size="'large'" @click="closeDialog"></a-icon>
            </div>
            
            <div class="DashAboutTitle"><font color="#CEB39A">NEWS</font></div>
            <div class="DashAboutLineDiv1"></div>
            <div class="DashAboutLineDiv2"></div>
            <div class="DashAboutLineDiv3"></div>

            <div>
                <!-- <div class="DashAboutMain" style="padding-left:20px;padding-right:20px;line-height: 28px;text-align: center;">
                    Providing you with breaking news and information about the safety and health concerns when traveling to Collection.
                </div> -->
                <div class="DashAboutMain">
                    For all resort updates such as renovations or any closures, please refer to the individual resort page. <br>
                    <!-- <font color="#FF0000" style="font-weight:700">COVID-19 UPDATE: </font>
                    Please refer to <font color="#2895f1" style="cursor:pointer;font-weight:600">https://covid19.health.gov.mv/en/</font> for all COVID-19 updates, stats and travel updates. -->
                </div>
                <!-- <div class="DashAboutMain" style="padding-left:20px;padding-right:20px;line-height: 28px;text-align: center;">
                    For more information on resort status, please contact: hello@vuecollection.com
                </div> -->

                <div class="newsViewImgDiv">
                    <img class="newsViewImg" src="../../assets/images/heart_img.png" alt="" width="50px">
                </div>
                <!-- <div class="DashAboutMain">
                    For up-to-date important travel information to Collection: <br>
                    <font color="#2895f1" style="cursor:pointer;font-weight:600;text-decoration: underline;" @click="gotoUrl('https://vue.vuemaldives.com/travelinformation')">https://vue.vuemaldives.com/travelinformation</font> -->
                    <!-- <a style="color: #2895f1;text-decoration: underline;" href="https://vue.vuemaldives.com/travelinformation"   target="_blank">https://vue.vuemaldives.com/travelinformation</a> -->
                <!-- </div> -->
                <div class="DashAboutMain">
                    For further information about COVID-19 in Collection:  <br>
                    <font color="#2895f1" style="cursor:pointer;font-weight:600;text-decoration: underline;" @click="gotoUrl('https://www.tourism.gov.mv/covid19')">https://www.tourism.gov.mv/covid19</font>
                </div>

                <div class="DashAboutMain">
                    <img class="newsViewImg" src="../../assets/images/law_img.png" alt="" width="50px">
                </div>
                <div class="DashAboutMain">
                    For daily news and political updates in Collection, please refer to Collection Independent, an award-winning news website.
                </div>
                <div class="DashAboutMain">
                    <font color="#2895f1" style="cursor:pointer;font-weight:600;text-decoration: underline;" @click="gotoUrl('https://maldivesindependent.com/')">https://maldivesindependent.com/</font>
                </div>

            </div>
        </div>
    </lazy-component>
</template>
<script>
export default {
    data() {
        return {

        }
    },

    methods:{
        closeDialog(){
          this.$emit('close');
        },
        gotoUrl(_url){
            window.open(_url, '_blank');
        }
    },
}
</script>