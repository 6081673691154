<template class="howtoGethereDiv">
    <lazy-component class="howtoGethereContainer">
        <div class="howtoGethereContainerMain">
            <div class="howtoGethereIconDiv">
                <a-icon class="howtoGethereIcon" type="close" :size="'large'" @click="closeDialog"></a-icon>
            </div>
            
            <div class="DashAboutTitle"><font color="#CEB39A">HOW TO GET HERE</font></div>
            <div class="DashAboutLineDiv1"></div>
            <div class="DashAboutLineDiv2"></div>
            <div class="DashAboutLineDiv3"></div>

            <div>
                <div class="DashAboutMain">
                    The main international airport hub to Collection is Velana International airport. <br> Also known as, Male airport (Airport code: MLE)

                </div>
                <div class="howtoGethereImgDiv">
                    <img class="howtoGethereImg" src="../../assets/images/plane_img.png" alt="" width="50px">
                </div>
                <div class="DashAboutMain">
                    Direct flights from Colombo, Sri Lanka are available to Gan International airport (airport code: GAN). <br>
                    This is located in the southern part of Collection (only a few resorts are located in this area).
                </div>
                <div class="howtoGethereImgDiv">
                    <img class="howtoGethereImg" src="../../assets/images/plane_img.png" alt="" width="50px">
                </div>
                <div class="DashAboutMain">
                    Maafaru International airport provides private jets and occasional charters to easily get to resorts such as Cheval Blanc Randheli, Velaa Private Island and Soneva Jani. <br>
                    A convenient speedboat ride are just minutes away from these resorts.

                </div>

                <!-- <div class="DashAboutSubTitle2">
                    MAJOR AIRLINES
                </div>
                <div style="height:1px;background-color:#EEEEEE;margin-left:20px;margin-right:20px;margin-top: 5px;margin-bottom: 20px;"></div>
                <div style="width:100%;display:flex">
                    <div style="margin-left:auto;margin-right:auto;text-align: center;">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major1.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major2.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major3.png" alt="" width="120px">
                    </div>
                </div>
                <div style="width:100%;display:flex;margin-top:20px">
                    <div style="margin-left:auto;margin-right:auto;text-align: center;">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major4.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major5.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major6.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major7.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major8.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/major9.png" alt="" width="120px">
                    </div>
                </div>
                
                <div class="DashAboutSubTitle2">
                    BUDGET AIRLINES
                </div>
                <div style="height:1px;background-color:#EEEEEE;margin-left:20px;margin-right:20px;margin-top: 5px;margin-bottom: 20px;"></div>
                <div style="width:100%;display:flex;margin-bottom:20px">
                    <div style="margin-left:auto;margin-right:auto;text-align: center;">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/budget1.png" alt="" width="120px">
                        <img style="padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;margin-bottom:5px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;" src="../../assets/images/budget2.png" alt="" width="120px">
                    </div>
                </div> -->
            </div>
        </div>
    </lazy-component>
</template>
<script>
export default {
    data() {
        return {

        }
    },

    methods:{
        closeDialog(){
          this.$emit('close');
        }
    },
}
</script>