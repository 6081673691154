<template>
    
  <lazy-component class="homeOffersPanelMAin preview-item-margin"> 
    <div class="homeOffersPanel">
      <div class="homeOffersMainPanel">
        <image-viewer v-if="cellData.info != undefined  && cellData.info.gallaryimages.length > 0" class="homeOffersMainImageViwer" :imageData="cellData.info == undefined ? [] : cellData.info.gallaryimages" @showImageViewer="showImageViewer"></image-viewer>
        <div v-else class="homeOffersMainImageGif">
            <img class="sliderImageGif" v-lazy="'../images/logo.gif'" draggable="false">
                
        </div>
      </div> 
      <div class="homeOffersSubPanel">
        <div class="homeOffersSubPanelDiv">

          <div class="homeOffersSubPanelLogoDiv">
            <div class="homeOffersSubPanelLogoCell">
              <img class="homeOffersSubPanelLogoCellImg" v-lazy="cellData.info != undefined && cellData.info.hotellogo != undefined ? cellData.info.hotellogo : ''" alt="" width="100%">
            </div>
          </div>

          <div class="homeOfferTitle font-new">{{cellData.info != undefined ? cellData.info.hotelname : 'Cheval Blanc Randheli'}}
          </div>
          <!-- <div class="homeOfferLocation">{{cellData.info != undefined ? cellData.info.hotelproperty.location : 'Raa atoll'}}</div> -->
          <!-- <div class="homeOfferLocation">Offer period:</div> -->
          <div class="homeOfferDetail font-new" v-if="$mq === 'lg'">{{moment(startDate).utc().format("Do MMMM, YYYY")}} - {{moment(endDate).utc().format("Do MMMM, YYYY")}}</div>
          <div class="homeOfferDetail font-new" v-else>{{moment(startDate).utc().format("Do MMM, YYYY")}} - {{moment(endDate).utc().format("Do MMM, YYYY")}}</div>
          <div v-if="$mq === 'lg'" class="homeOfferDetailListDiv">
            <div v-for="column, index in columns(cellData.features)"  class="homeOfferDetailListCell" :key="index">              
              <div class="homeOfferDetailListCellDiv" :key="item" v-for="item in column">
                <div class="homeOfferDetailListCellSub">
                  <img class="homeOfferDetailListCellImg" src="../../assets/images/checkIcon.png" alt="" width="12">
                  <div class="homeOfferItem font-new">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="homeOfferDetailListDiv">
            <div v-for="item in cellData.features" :key="item" class="homeOfferDetailListCellDiv">
              <img class="homeOfferDetailListCellImg" src="../../assets/images/checkIcon_black.png" alt="" width="14">
              <div class="homeOfferItem font-new">{{item}}</div>
            </div>
          </div>

        </div>
        <div class="homeOffersSubPanelDiv2">
          <div class="homeOffersSubPanelDiv2Cell">
            <div class="homeOffersSubPanelCellDiv">
              <div class="homeOfferPriceDetail font-new">{{cellData.priceper + 1}} nights from</div>
              <div class="homeOfferPrice font-new">{{currencyName}} {{addZeroes(Math.round(cellData.showoldprice * currency))}} </div>
            </div>
            <div class="homeOfferTotalPrice font-new">{{currencyName}} {{addZeroes(Math.round(cellData.shownewprice * currency))}}<span style="font-size: 16px;">/2 person</span></div>
            <div class="homeOfferPriceDetailTax font-new">Taxes & service charges included
              <!-- <br>For 2 adults. Taxes included. -->
            </div>
          </div>
          <div class="homeOffersSubPanelBtnDiv">
            <button class="homeOfferViewResort font-new" @click="gotoResortDetail(cellData.url)">VIEW RESORT</button>
          </div>
        </div>
      </div>
    </div> 
    
    <a-modal
        class="hotelOfferModel"
        v-model="visibleImageViewer"
        title=""
        :footer="null"
        :width="'auto'"
        :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
    >
        <image-previewer v-if="visibleImageViewer" :imageData="cellData.info != undefined ? cellData.info.gallaryimages : []" :imageIndex="indexImageViewer" @close="showImageViewer(false)"/>
    </a-modal>
  </lazy-component>
</template>
<script>
import ImageViewer from './hotel-image-viewer.vue';
import moment from 'moment';
import ImagePreviewer from './image-previewer.vue';
// const pricePerList = [
//     '1 nights from', '4 nights from', '7 nights from'
// ];
export default{
  data() {
    return {
        currency:1.0,
        currencyName:'USD',
        diffDay:3,
        startDate: new Date(),
        endDate: new Date(),
        visibleImageViewer:false,
        indexImageViewer: 0,
    }
  },
    props: {
      cellData: Object
    },
    created(){
      this.currency = this.$store.state.selectedCurrency.rate;
      this.currencyName = this.$store.state.selectedCurrency.name;

      if (this.cellData.priceper != undefined)
        this.diffDay = this.cellData.priceper;
        
      this.startDate = new Date(this.cellData.startdate);
      this.endDate = new Date(this.cellData.enddate);
      // this.endDate.setDate(this.endDate.getDate() + this.diffDay)
    },

  components: {
    ImageViewer,
    ImagePreviewer
  },
  methods:{
    moment,
    addZeroes(num){
        let textNum = num.toString();
        let decimalResult = Number(textNum).toFixed(Math.max(textNum.split('.')[1]?.length, 0) || 0);
        let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return thousand;
    },
    columns(_items) {
      let columns = [];
      const cols = 2;
      let mid = Math.ceil(_items.length / cols)
      for (let col = 0; col < cols; col++) {
        columns.push(_items.slice(col * mid, col * mid + mid))
      }
      return columns
    },
    
    showImageViewer(isShow, index){
      
        if (this.cellData.info != undefined){
          this.visibleImageViewer = isShow && this.cellData.info.gallaryimages.length > 0;
          this.indexImageViewer = index;
        }
    },
    gotoResortDetail(){
      // window.scrollTo(0, top);
      // if (_url != undefined && _url != ''){
      //   window.open(_url,"_blank");// "_self"
      // }
      // else{
        // this.$router.push({ name: 'hotelDetail', params: { hotelname: decodeURIComponent(this.cellData.info.hotelname).replace(/\s/g, '').toLowerCase(), hotelid: this.cellData.hotelid }});

        this.$router.push({ name: 'hotelDetail', path: '/hotelDetail',params: { hotelname: decodeURIComponent(this.cellData.info.hotelname).replace(/\s/g, '-').toLowerCase()}});
      // }
    },
    getResortBestForStr(_array) {
        const resortList = {
            0:'Romance',
            1:'Family',
            2:'Friends getaway',
            3:'Solo traveler',
            4:'Foodies',
            5:'Spa & wellness',
            6:'Wellness retreat',
            7:'All-inclusive',
            8:'Best for diving',
            9:'Great house reef',
            10:'Snorkeling',
            11:'Quick airport transfer',
            12:'Underwater dining',
            13:'Overwater villas only',
            14:'Adults-only resort',
            15:'Best for surfing',
            16:'Remote and secluded', 
        }
        var _arratStr = [];
        for(let i = 0; i < _array.length; i++){
          if (_array[i] in resortList)
            _arratStr.push(resortList[_array[i]])
        }
        return _arratStr;
    },
  }
};
</script>
<style>

    .imageMainView{
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    .preview-item-margin{
      margin-bottom:-137.9px
    }
    @media only screen and (max-width: 1200px) {
    .preview-item-margin{
        margin-top:0px;
        margin-bottom:0px
      }
    }
</style>
