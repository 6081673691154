<template class="besttimeYogoDiv">
    <lazy-component class="besttimeYogoDivContainer">
        <div class="besttimeYogoContainerMain">
            <div class="besttimeTogoIconDiv">
                <a-icon class="besttimeTogoIcon" type="close" :size="'large'" @click="closeDialog"></a-icon>
            </div>
            
            <div class="DashAboutTitle"><font color="#CEB39A">BEST TIME TO GO</font></div>
            <div class="DashAboutLineDiv1"></div>
            <div class="DashAboutLineDiv2"></div>
            <div class="DashAboutLineDiv3"></div>

            <div>
                <div class="DashAboutMain" style="text-align: left;">
                    Average temperature for Collection is 29°C (84°F). Lowest average of 26°C (79°F).
                </div>

                <div class="besttimeTogoContentDiv1">
                    <div class="DashAboutSubTitle">
                        Mid-December - January (Festive Season)
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Price:</font>
                        $$$$$
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Weather:</font>
                        Dry and warm with little or no rainfall and beautiful blue skies.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Why go: </font>
                        Extravagant festivities organized by the resorts to celebrate the festive holidays. 
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Scuba Diving:</font>
                        Fantastic visibility along the Eastern atolls. Higher chances of manta ray spotting on Western atolls.
                    </div>
                </div>
                
                <div class="besttimeTogoContentDiv1">
                    <div class="DashAboutSubTitle">
                        January - April (High Season)
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Price:</font>
                        $$$$
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Weather:</font>
                        Clear blue skies and optimal sunshine.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Why go: </font>
                        Driest and warmest period to enjoy Collection!
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Surfing: </font>
                        Surf season begin in April.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Scuba Diving:</font>
                        Fantastic visibility along the Eastern atolls.  Higher chances of manta ray spotting on Western atolls.
                    </div>
                </div>
                
                <div class="besttimeTogoContentDiv1">
                    <div class="DashAboutSubTitle">
                        May - September (Low Season)
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Price:</font>
                        $$
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Weather:</font>
                        Considered the wet season. 
                        <!-- Southern atolls have less chances of rainfall. -->
                    </div>
                    <div class="DashAboutItemTitle">
                        <font color="#CEB39A" style="font-weight: 600;">August:</font>
                        Considered the month with heavier rainstorms.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font color="#CEB39A" style="font-weight: 600;">September: </font>
                        Considered rainiest month.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Why go: </font>
                        Fewer guests, lower rates and attractive offers.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Surfing: </font>
                        Best surfing at this time in the Northern and Central atolls. Great waves in the Southern atolls from August.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Scuba Diving:</font>
                        Hanifaru Bay in Baa Atoll offers some of the best sightings of manta rays and whale sharks.
                    </div>
                </div>
                
                <div class="besttimeTogoContentDiv1" >
                    <div class="DashAboutSubTitle">
                        October - Mid December (Shoulder Season)
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Price:</font>
                        $$$
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Weather:</font>
                        Occasional tropical showers.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Why go: </font>
                        Passing wet season providing more sunshine with attractive rates before the holiday rates kicks in.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Surfing: </font>
                        Great until Mid-November in the Southern atolls.
                    </div>
                    <div class="DashAboutItemTitle">
                        <font style="font-weight: 600;">Scuba Diving:</font>
                        Until November, Hanifaru Bay in Baa Atoll offers some of the best sightings of manta rays and whale sharks. Nov - Dec, Higher chances of manta ray spotting on Western atolls.(Nov 01 ~ Dec 31)
                    </div>
                </div>
            </div>
        </div>
    </lazy-component>
</template>
<script>
export default {
    data() {
        return {

        }
    },

    methods:{
        closeDialog(){
          this.$emit('close');
        }
    },
}
</script>