<template class="beforYougoDiv">
  <lazy-component class="beforYougoContainer">
    <div class="beforYougoContainerMain">
      <div class="beforYougoIconDiv">
        <a-icon
          class="beforYougoIconTop"
          type="close"
          :size="'large'"
          @click="closeDialog"
        ></a-icon>
      </div>

      <div class="DashAboutTitle">
        <font color="#CEB39A">BEFORE YOU GO</font>
      </div>
      <div class="DashAboutLineDiv1"></div>
      <div class="DashAboutLineDiv2"></div>
      <div class="DashAboutLineDiv3"></div>

      <div>
        <div class="beforYougoImgDiv">
          <img
            class="beforYougoImg"
            src="../../assets/images/doller_img.png"
            alt=""
            width="50px"
          />
        </div>
        <div class="DashAboutMain">
          All properties accept USD currency. <br />
          Tourist visa is granted for all nationalities on arrival to Collection.
          Therefore, a foreigner traveling to Collection as a tourist does not
          require pre-approval for a visa.
        </div>

        <div class="beforYougoImgDiv">
          <img
            class="beforYougoImg"
            src="../../assets/images/bag_img.png"
            alt=""
            width="50px"
          />
        </div>
        <div class="DashAboutMain">
          <font style="font-weight: bold">Seaplane transfers:</font>
          44lbs / 20kg for check-in luggage and 11lbs / 5kg for hand luggage per
          person. Additional weight may be chargeable.
        </div>
        <div class="DashAboutMain">
          <font style="font-weight: bold">Domestic flights:</font>
          44lbs / 20kg for check-in luggage and 11lbs / 5kg for hand luggage per
          person. Additional weight may be chargeable.
        </div>

        <div class="beforYougoImgDiv">
          <img
            class="beforYougoImg"
            src="../../assets/images/doc_img.png"
            alt=""
            width="50px"
          />
        </div>
        <div class="DashAboutMain">
          <font style="font-weight: bold">MANDATORY HEALTH FORM</font> <br />
          In accordance with health requirements, anybody traveling to the
          Collection is required to submit an online self-conducted health
          declaration form within 72 hours prior to their arrival. Please click
          on this
          <font
            color="#2895f1"
            style="
              cursor: pointer;
              font-weight: 600;
              text-decoration: underline;
            "
            @click="gotoUrl('https://imuga.immigration.gov.mv/traveller')"
            >link</font
          >.<br />
          This form is located by clicking 'Traveller Declaration'. <br />
          The same link will also provide a departure form mandatory to be
          completed prior to your check-in for your departing flight out of
          Collection.
        </div>
        <div class="DashAboutMain">
          <font style="font-weight: bold">TRAVEL INSURANCE</font> <br />
          Introducing Allied Inbound Insurance that covers tourists for specific
          expenses that may incur due to a positive diagnosis of Covid-19 while
          on holiday in paradise. Please click this
          <font
            color="#2895f1"
            style="
              cursor: pointer;
              font-weight: 600;
              text-decoration: underline;
            "
            @click="
              gotoUrl(
                'https://www.allianztravelinsurance.com/travel/guides/maldives.htm?accam=F202942&gad_source=1&gclid=Cj0KCQjwir2xBhC_ARIsAMTXk843QUqg5BxeDfPQOVBCrQZ5PQEceMkGNobo4IW7YQW_JyOjuK5P1FUaAhOkEALw_wcB'
              )
            "
            >link</font
          >
          to find the different options to select prior to your journey to
          assure you travel with safety and peace of mind to the Collection.
        </div>

        <div class="beforYougoImgDiv">
          <img
            class="beforYougoImg"
            src="../../assets/images/dressing_img.png"
            alt=""
            width="50px"
          />
        </div>
        <div class="DashAboutMain">
          The dining areas at the resorts follow an island chic dress code
          (smart casual). <br />
          However, shorts and flip-flops may not be suitable for evening attire
          at certain restaurants.
        </div>

        <div class="beforYougoImgDiv">
          <img
            class="beforYougoImg"
            src="../../assets/images/plug_img.png"
            alt=""
            width="50px"
          />
        </div>
        <div class="DashAboutMain">
          The standard voltage is 230 V and the standard frequency is 50 Hz.
          <br />
          The standard socket is the UK-style three-pin, although there are some
          variations, so an international adaptor can be useful but resorts will
          supply adaptors.
        </div>
      </div>
    </div>
  </lazy-component>
</template>
<script>
export default {
  data() {
    return {};
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    gotoUrl(_url) {
      window.open(_url, "_blank");
    },
  },
};
</script>
